import _schema from "./schema";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createTransaction = void 0;
const schema_1 = _schema;
function createTransaction(signerId, publicKey, receiverId, nonce, actions, blockHash) {
  return new schema_1.Transaction({
    signerId,
    publicKey,
    nonce,
    receiverId,
    actions,
    blockHash
  });
}
exports.createTransaction = createTransaction;
export default exports;