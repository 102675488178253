import * as _types2 from "@near-js/types";
var _types = "default" in _types2 ? _types2.default : _types2;
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Action = exports.SignedDelegate = exports.DeleteAccount = exports.DeleteKey = exports.AddKey = exports.Stake = exports.Transfer = exports.FunctionCall = exports.DeployContract = exports.CreateAccount = exports.IAction = exports.AccessKey = exports.AccessKeyPermission = exports.FullAccessPermission = exports.FunctionCallPermission = void 0;
const types_1 = _types;
class Enum {
  constructor(properties) {
    if (Object.keys(properties).length !== 1) {
      throw new Error("Enum can only take single value");
    }
    Object.keys(properties).map(key => {
      this[key] = properties[key];
      this.enum = key;
    });
  }
}
class FunctionCallPermission extends types_1.Assignable {}
exports.FunctionCallPermission = FunctionCallPermission;
class FullAccessPermission extends types_1.Assignable {}
exports.FullAccessPermission = FullAccessPermission;
class AccessKeyPermission extends Enum {}
exports.AccessKeyPermission = AccessKeyPermission;
class AccessKey extends types_1.Assignable {}
exports.AccessKey = AccessKey;
class IAction extends types_1.Assignable {}
exports.IAction = IAction;
class CreateAccount extends IAction {}
exports.CreateAccount = CreateAccount;
class DeployContract extends IAction {}
exports.DeployContract = DeployContract;
class FunctionCall extends IAction {}
exports.FunctionCall = FunctionCall;
class Transfer extends IAction {}
exports.Transfer = Transfer;
class Stake extends IAction {}
exports.Stake = Stake;
class AddKey extends IAction {}
exports.AddKey = AddKey;
class DeleteKey extends IAction {}
exports.DeleteKey = DeleteKey;
class DeleteAccount extends IAction {}
exports.DeleteAccount = DeleteAccount;
class SignedDelegate extends IAction {}
exports.SignedDelegate = SignedDelegate;
/**
 * Contains a list of the valid transaction Actions available with this API
 * @see {@link https://nomicon.io/RuntimeSpec/Actions.html | Actions Spec}
 */
class Action extends Enum {}
exports.Action = Action;
export default exports;