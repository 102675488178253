import * as _types2 from "@near-js/types";
var _types = "default" in _types2 ? _types2.default : _types2;
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Signature = void 0;
const types_1 = _types;
class Signature extends types_1.Assignable {}
exports.Signature = Signature;
export default exports;